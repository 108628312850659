// import React, { useContext, useEffect, useRef, useState } from "react";
// import { Nav, Tab, Collapse, Button } from "react-bootstrap";
// import { Link, navigate } from "gatsby";
// import PageWrapper from "../components/PageWrapper";
// import ProfileSidebar from "../components/ProfileSidebar";
// import network from "../../constants/Network";
// import GlobalContext from "../context/GlobalContext";
// import axios from "axios";

// import imgB1 from "../assets/image/l2/png/featured-job-logo-1.png";
// import imgB2 from "../assets/image/l1/png/feature-brand-1.png";
// import imgB3 from "../assets/image/svg/harvard.svg";
// import imgB4 from "../assets/image/svg/mit.svg";

// import imgT1 from "../assets/image/l3/png/team-member-1.png";
// import imgT2 from "../assets/image/l3/png/team-member-2.png";
// import imgT3 from "../assets/image/l3/png/team-member-3.png";
// import imgT4 from "../assets/image/l3/png/team-member-4.png";
// import imgT5 from "../assets/image/l3/png/team-member-5.png";

// import imgL from "../assets/image/svg/icon-loaction-pin-black.svg";
// import { backgroundColor } from "styled-system";

// const UserProfileDetailsView = ({ location }) => {

//   const params = new URLSearchParams(location.search);
//   const isMountedRef = useRef(false);

//   const [user, setUser] = useState('')
//   const gContext = useContext(GlobalContext);
//   const userId = gContext?.user?.id || '';
//   const { logedInUser } = useContext(GlobalContext);
//   const [logedInUserId, setLogedInUserId] = useState('');

//   const imageBaseUrl = `${network.serverip}/images/`;

//   const [collapsible, setCollapsible] = useState('');

//   const documentIconMap = {
//     resume: 'fas fa-file-alt',
//     aadhaar: 'fas fa-id-card',
//     other_address_proof: 'fas fa-file',
//   };

//   const handleWordChange = (word) => {
//     return word
//       .replace(/_/g, ' ')
//       .toUpperCase();
//   };

//   const handleFileDownload = async (fileName, name) => {
//     try {
//       // Define the document URL
//       const documentUrl = `${network.serverip}/storage/${fileName}`;
//       console.log('Downloading from URL:', documentUrl);

//       // Fetch the document from the server using Axios
//       const response = await axios.get(documentUrl, {
//         responseType: 'blob' // Important: This ensures that the response is treated as a Blob
//       });

//       // Check if the response is successful
//       if (response.status !== 200) {
//         throw new Error('Failed to download document');
//       }

//       // Convert the response data into a Blob URL
//       const fileUrl = window.URL.createObjectURL(response.data);

//       // Create an anchor element to trigger the download
//       const a = document.createElement('a');
//       a.href = fileUrl;
//       a.download = name; // Set the file name

//       // Programmatically trigger a click event to download the file
//       document.body.appendChild(a);
//       a.click();

//       // Cleanup: Remove the anchor and revoke the Blob URL
//       a.remove();
//       window.URL.revokeObjectURL(fileUrl);

//       console.log(`Download complete: ${name}`);

//       // Optionally, show a notification after the download completes
//       alert(`The file "${name}" has been downloaded successfully.`);

//     } catch (error) {
//       console.error('Error downloading the document:', error);

//       // Optional: Show a notification in case of failure
//       alert(`Failed to download the file "${name}". Please try again.`);
//     }
//   };

//   useEffect(() => {
//     if (logedInUser && logedInUser.id) {
//       setLogedInUserId(logedInUser.id)
//     }
//   }, [logedInUser]);

//   const fetchUserDetails = async () => {
//     try {
//       const response = await axios.post(`${network.serverip}/api/profile-details-for-web`, { userId: userId });

//       if (response.data.success && isMountedRef.current) {
//         setUser(response.data.userData);
//         console.log('This is the fetched user details:', response.data.userData);
//       } else if (!response.data.success) {
//         if (isMountedRef.current) {
//           setUser('');
//         }
//         console.error('Failed to fetch user details');
//       }
//     } catch (error) {
//       if (isMountedRef.current) {
//         setUser('');
//       }
//       console.error('Error fetching user details:', error);
//     }
//   };

//   useEffect(() => {
//     if (!userId) {
//       console.log('Waiting for the candidate id');
//       return;
//     }

//     isMountedRef.current = true;

//     fetchUserDetails();

//     return () => {
//       isMountedRef.current = false;
//     };
//   }, [userId]);

//   const styles = UserProfileDetailsViewStyling();

//   return (
//     <>
//       <PageWrapper headerConfig={{ button: "profile" }}>
//         <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
//           <div className="container">
//             {/* <!-- back Button --> */}
//             <div className="row justify-content-center">
//               <div className="col-12 dark-mode-texts">
//                 <div className="mb-9">
//                   <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
//                     if (window.history.length > 1) {
//                       navigate(-1);
//                     } else {
//                       navigate('/dashboard-main');
//                     }
//                   }} className="d-flex align-items-center ml-4">
//                     {" "}
//                     <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
//                     <span className="text-uppercase font-size-3 font-weight-bold text-gray">
//                       Back
//                     </span>
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="row d-flex justify-content-center">
//               <div className="col-12 col-xxl-3 col-lg-4 col-md-5 mb-11 mb-lg-0">
//                 <div>
//                   <div className="pl-lg-5">
//                     <div className="bg-white shadow-9 rounded-4">
//                       <div className="px-5 py-11 text-center border-bottom border-mercury">
//                         <button
//                           className="mb-4"
//                           onClick={() => gContext.toggleImageViewModal(user?.profile?.image)}
//                           style={styles.imageViewButton}
//                         >
//                           <img
//                             src={user?.profile?.image ? imageBaseUrl + user.profile.image : imageBaseUrl + "profile_default_photo.png"}
//                             className="circle-54"
//                             alt=""
//                           />
//                         </button>
//                         <h4 className="mb-0"
//                           onClick={(e) => e.preventDefault()}
//                         >
//                           <p >
//                             {user?.name}
//                           </p>
//                         </h4>
//                         <p className="text-gray font-size-4 mb-8">
//                           {user?.roleforapp?.name}
//                         </p>
//                       </div>

//                       <div className="px-9 pt-lg-5 pt-9 pt-xl-9 pb-5">

//                         <div className="w-100 d-flex justify-content-end">
//                           <Link
//                             to="/user-profile-edit"
//                           >
//                             <i className="fas fa-pencil-alt text-success"></i>
//                           </Link>
//                         </div>

//                         <h5 className="text-black-2 mb-8 font-size-5">Contact Info</h5>

//                         {(user && (user?.profile?.street_address || user?.profile?.city || user?.profile?.state || user?.profile?.country || user?.profile?.pin_code)) ? (
//                           <div className="mb-7">
//                             <div className="row d-flex align-items-start">
//                               <div className="col-1 d-flex justify-content-start">
//                                 <p className="font-size-4 mb-0">
//                                   <i className="fas fa-map-marker-alt text-success"></i>
//                                 </p>
//                               </div>
//                               <div className="col-10 d-flex justify-content-start">
//                                 <p className="font-size-4 mb-0 text-black-2">
//                                   <span>{user?.profile?.street_address}</span>
//                                   <br />
//                                   <span>
//                                     {user?.profile?.city}
//                                     {user?.profile?.state ? `, ${user?.profile?.state}` : ''}
//                                   </span>
//                                   <br />
//                                   <span>{user?.profile?.country}</span>
//                                   <br />
//                                   <span>{user?.profile?.pin_code}</span>
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         ) : null}

//                         {user && user?.email &&
//                           <div className="mb-7">
//                             <div className="row d-flex align-items-start">
//                               <div className="col-1 d-flex justify-content-start">
//                                 <p className="font-size-4 mb-0">
//                                   <i className="fas fa-envelope text-success"></i>
//                                 </p>
//                               </div>
//                               <div className="col-10 d-flex justify-content-start">
//                                 <a
//                                   className="font-size-4 mb-0 text-black-2"
//                                   href={undefined}
//                                 >
//                                   {user?.email}
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                         }

//                         {user && user?.mobile_number &&
//                           <div className="mb-7">
//                             <div className="row d-flex align-items-start">
//                               <div className="col-1 d-flex justify-content-start">
//                                 <p className="font-size-4 mb-0">
//                                   <i className="fas fa-phone-alt text-success"></i>
//                                 </p>
//                               </div>
//                               <div className="col-10 d-flex justify-content-start">
//                                 <a
//                                   className="font-size-4 mb-0 text-black-2"
//                                   href={undefined}
//                                 >
//                                   {user?.mobile_number}
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                         }

//                         <h5 className="text-black-2 mb-8 mt-15 font-size-5">Personal Details</h5>

//                         {(user && (user?.profile?.gender)) ? (
//                           <div className="mb-7">
//                             <div className="row d-flex align-items-start">
//                               <div className="col-1 d-flex justify-content-start">
//                                 <p className="font-size-4 mb-0">
//                                   <i className="fas fa-user-alt text-success"></i>
//                                 </p>
//                               </div>
//                               <div className="col-10 d-flex justify-content-start">
//                                 <p className="font-size-4 mb-0 text-black-2">
//                                   <span>{`Gender: ${user?.profile?.gender}`}</span>
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         ) : null}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 col-xxl-6 col-lg-8 col-md-7 order-2 order-xl-1">
//                 <div className="bg-white rounded-4 shadow-9">
//                   <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">
//                     <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
//                       About
//                     </h4>
//                     <p className="font-size-4 mb-8" style={{ textAlign: 'justify' }}>
//                       {user?.profile?.about_me}
//                     </p>
//                   </div>

//                   <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
//                     <Link
//                       to="/user-qualification"
//                       className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
//                       style={styles.collapsibleHeadingButton}
//                     >
//                       <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
//                         Qualification
//                       </h4>
//                       <i
//                         className={`fas fa-chevron-right text-success font-size-6`}
//                       ></i>
//                     </Link>
//                   </div>

//                   <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
//                     <Link
//                       to="/#"
//                       className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
//                       style={styles.collapsibleHeadingButton}
//                     >
//                       <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
//                         Upload documents
//                       </h4>
//                       <i
//                         className={`fas fa-chevron-right text-success font-size-6`}
//                       ></i>
//                     </Link>
//                   </div>

//                   <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
//                     <Link
//                       to="/#"
//                       className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
//                       style={styles.collapsibleHeadingButton}
//                     >
//                       <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
//                         Upload certificates
//                       </h4>
//                       <i
//                         className={`fas fa-chevron-right text-success font-size-6`}
//                       ></i>
//                     </Link>
//                   </div>

//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </PageWrapper>
//     </>
//   );
// };
// export default UserProfileDetailsView;

// function UserProfileDetailsViewStyling() {
//   const styles = {
//     collapsibleHeadingButton:
//     {
//       border: 'none',
//       backgroundColor: 'transparent',
//       outline: 'none',
//       padding: 0,
//     },
//     downloadButton: {
//       border: 'none',
//       backgroundColor: 'transparent',
//       outline: 'none',
//       padding: 0,
//     },
//     imageViewButton: {
//       outline: 'none',
//       border: 'none',
//       background: 'none',
//       padding: '0',
//     },
//   };

//   return styles;
// }


import React, { useContext, useEffect, useRef, useState } from "react";
import { Nav, Tab, Collapse, Button } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";

const UserProfileDetailsView = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const [user, setUser] = useState('');
  const gContext = useContext(GlobalContext);
  const userId = gContext?.user?.id || '';
  const currentUser = gContext.user;
  const currentProfile = gContext.profile;
  const [showMore, setShowMore] = useState(false);

  const imageBaseUrl = `${network.serverip}/images/`;

  const [collapsible, setCollapsible] = useState('');

  const documentIconMap = {
    resume: 'fas fa-file-alt',
    aadhaar: 'fas fa-id-card',
    other_address_proof: 'fas fa-file',
  };

  const handleWordChange = (word) => {
    return word
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  const handleFileDownload = async (fileName, name) => {
    try {
      // Define the document URL
      const documentUrl = `${network.serverip}/storage/${fileName}`;
      console.log('Downloading from URL:', documentUrl);

      // Fetch the document from the server using Axios
      const response = await axios.get(documentUrl, {
        responseType: 'blob' // Important: This ensures that the response is treated as a Blob
      });

      // Check if the response is successful
      if (response.status !== 200) {
        throw new Error('Failed to download document');
      }

      // Convert the response data into a Blob URL
      const fileUrl = window.URL.createObjectURL(response.data);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = name; // Set the file name

      // Programmatically trigger a click event to download the file
      document.body.appendChild(a);
      a.click();

      // Cleanup: Remove the anchor and revoke the Blob URL
      a.remove();
      window.URL.revokeObjectURL(fileUrl);

      console.log(`Download complete: ${name}`);

      // Optionally, show a notification after the download completes
      alert(`The file "${name}" has been downloaded successfully.`);

    } catch (error) {
      console.error('Error downloading the document:', error);

      // Optional: Show a notification in case of failure
      alert(`Failed to download the file "${name}". Please try again.`);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await axios.post(`${network.serverip}/api/profile-details-for-web`, { userId: userId });

      if (response.data.success && isMountedRef.current) {
        setUser(response.data.userData);
        console.log('This is the fetched user details:', response.data.userData);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUser('');
        }
        console.error('Failed to fetch user details');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUser('');
      }
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (!userId) {
      return;
    }

    isMountedRef.current = true;

    fetchUserDetails();

    return () => {
      isMountedRef.current = false;
    };
  }, [userId, currentUser, currentProfile]);

  const styles = UserProfileDetailsViewStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 mt-4 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/dashboard-main');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-xl-12 col-lg-12 mb-5">
                <div className="bg-white rounded-4 shadow-9">

                  <div className="px-9 py-11 text-center border-bottom border-mercury">

                    <button
                      className="mb-4"
                      onClick={() => gContext.togglePhotoUpdateModal()}
                      style={styles.imageViewButton}
                    >
                      <div className="d-flex justify-content-start mt-7">
                        <div style={styles.imageContainer}>
                          <div className="d-none d-sm-block">
                            <img
                              src={user?.profile?.image ?
                                imageBaseUrl + user.profile.image :
                                imageBaseUrl + "profile_default_photo.png"}
                              alt=""
                              style={styles.imageCircle}
                            />
                            <div
                              className="text-success"
                              style={{
                                ...styles.imageEditButton,
                                borderColor: 'currentColor',
                              }}
                            >
                              <i className="fas fa-pencil-alt text-success"></i>
                            </div>
                          </div>
                          <div className="d-block d-sm-none">
                            <img
                              src={user?.profile?.image ?
                                imageBaseUrl + user.profile.image :
                                imageBaseUrl + "profile_default_photo.png"}
                              className="circle-54"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </button>
                    <h4 className="mb-0 mt-9"
                    >
                      <p >
                        {user?.name}
                      </p>
                    </h4>
                    <p className="text-gray font-size-4 mb-8">
                      {user?.roleforapp?.name}
                    </p>
                  </div>

                  <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">

                    <div className="row">

                      <div className="col-10">

                        <div className="border-bottom border-mercury">

                          <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                            About
                          </h4>

                          <p className="font-size-4 mb-8" style={{ textAlign: 'justify' }}>
                            {user?.profile?.about_me &&
                              <span>
                                {(showMore || user.profile.about_me.length <= 150)
                                  ? `${user.profile.about_me} `
                                  : `${user.profile.about_me.slice(0, 150)}... `}
                                {user.profile.about_me.length > 150 && (
                                  <button onClick={() => setShowMore(!showMore)} style={styles.showMoreButton}>
                                    {showMore ? 'Show less' : 'Show more'}
                                  </button>
                                )}
                              </span>
                            }
                          </p>
                        </div>


                        <div className="mt-10 border-bottom border-mercury">

                          <h5 className="text-black-2 mb-8 font-size-5">Contact Info</h5>

                          {(user && (user?.profile?.street_address || user?.profile?.city || user?.profile?.state || user?.profile?.country || user?.profile?.pin_code)) ? (
                            <div className="mb-7">
                              <div className="row d-flex align-items-start">
                                <div className="col-1 d-flex justify-content-start">
                                  <p className="font-size-4 mb-0">
                                    <i className="fas fa-map-marker-alt text-success"></i>
                                  </p>
                                </div>
                                <div className="col-10 d-flex justify-content-start">
                                  <p className="font-size-4 mb-0 text-black-2">
                                    <span>{user?.profile?.street_address}</span>
                                    <br />
                                    <span>
                                      {user?.profile?.city}
                                      {user?.profile?.state ? `, ${user?.profile?.state}` : ''}
                                    </span>
                                    <br />
                                    <span>{user?.profile?.country}</span>
                                    <br />
                                    <span>{user?.profile?.pin_code}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {user && user?.email &&
                            <div className="mb-7">
                              <div className="row d-flex align-items-start">
                                <div className="col-1 d-flex justify-content-start">
                                  <p className="font-size-4 mb-0">
                                    <i className="fas fa-envelope text-success"></i>
                                  </p>
                                </div>
                                <div className="col-10 d-block d-lg-flex justify-content-start">
                                  <p
                                    className="font-size-4 mb-0 text-black-2 text-truncate"
                                    style={{
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}
                                    aria-label={`Email: ${user?.email || 'N/A'}`}
                                    title={`Email: ${user?.email || 'N/A'}`}
                                  >
                                    {user?.email}
                                  </p>
                                  {user?.is_email_verified === 0 &&
                                    <button
                                      onClick={() => gContext.toggleEmailVerifyModal()}
                                      className="ml-lg-5 ml-0"
                                      style={styles.verifyButton}>
                                      <span className="font-size-4 mb-0 text-yellow font-weight-semi-bold">
                                        Your email is not verified yet.{' '}
                                        <span
                                          style={{
                                            color: 'blue',
                                            textDecoration: 'underline',
                                          }}
                                        >Verify Now</span>
                                      </span>
                                    </button>
                                  }
                                </div>
                              </div>
                            </div>
                          }

                          {user && user?.mobile_number &&
                            <div className="mb-7">
                              <div className="row d-flex align-items-start">
                                <div className="col-1 d-flex justify-content-start">
                                  <p className="font-size-4 mb-0">
                                    <i className="fas fa-phone-alt text-success"></i>
                                  </p>
                                </div>
                                <div className="col-10 d-flex justify-content-start">
                                  <p
                                    className="font-size-4 mb-0 text-black-2"
                                    aria-label={`Mobile Number: ${user?.mobile_number || 'N/A'}`}
                                    title={`Mobile Number: ${user?.mobile_number || 'N/A'}`}
                                  >
                                    {user?.mobile_number}
                                  </p>
                                </div>
                              </div>
                            </div>
                          }
                        </div>

                        <div className="mb-8 mt-10">

                          <h5 className="text-black-2 font-size-5">Personal Details</h5>
                          {(user && (user?.profile?.gender)) ? (
                            <div className="mb-7 mt-7">
                              <div className="row d-flex align-items-start">
                                <div className="col-1 d-flex justify-content-start">
                                  <p className="font-size-4 mb-0">
                                    <i className="fas fa-user-alt text-success"></i>
                                  </p>
                                </div>
                                <div className="col-10 d-flex justify-content-start">
                                  <p className="font-size-4 mb-0 text-black-2">
                                    <span>{`Gender: ${user?.profile?.gender}`}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>

                      </div>

                      <div className="col-2 d-flex justify-content-end align-items-center">
                        <Link
                          to="/user-profile-edit"
                          aria-label="Edit Profile"
                          title="Edit Profile"
                        >
                          <i className="fas fa-chevron-right text-success font-size-6"></i>
                        </Link>
                      </div>

                    </div>

                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12">
                    <div className="mb-8 mt-10">
                      <h5 className="text-black-2 mb-8 font-size-5">Password</h5>
                      <div className="mb-7">
                        <div className="row d-flex align-items-center">
                          <div className="col-1 d-flex justify-content-start align-items-center">
                            <p className="font-size-4 mb-0">
                              <i className="fas fa-lock text-success"></i>
                            </p>
                          </div>
                          <div className="col-11 d-flex justify-content-between align-items-center">
                            <p className="font-size-4 mb-0 text-black-2 text-align-center">
                              ********
                            </p>
                            <button
                              type="button"
                              style={styles.collapsibleHeadingButton}
                              onClick={() => { gContext.togglePasswordUpdateModal() }}
                            >
                              <i className="fas fa-pencil-alt text-success"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <Link
                      to="/user-qualification"
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Qualification
                      </h4>
                      <i
                        className={`fas fa-chevron-right text-success font-size-6`}
                      ></i>
                    </Link>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <Link
                      to="/user-documents"
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Upload documents
                      </h4>
                      <i
                        className={`fas fa-chevron-right text-success font-size-6`}
                      ></i>
                    </Link>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <Link
                      to="/user-certificates"
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Upload certificates
                      </h4>
                      <i
                        className={`fas fa-chevron-right text-success font-size-6`}
                      ></i>
                    </Link>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <Link
                      to="/user-time-availability"
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Time Availability
                      </h4>
                      <i
                        className={`fas fa-chevron-right text-success font-size-6`}
                      ></i>
                    </Link>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <Link
                      to="/user-settings"
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Settings
                      </h4>
                      <i
                        className={`fas fa-chevron-right text-success font-size-6`}
                      ></i>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default UserProfileDetailsView;

function UserProfileDetailsViewStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    imageCircle: {
      width: '12.5rem',
      height: '12.5rem',
      borderRadius: '50%',
      overflow: 'hidden',
      objectFit: 'cover',
    },
    imageContainer: {
      position: 'relative',
      display: 'flex',
    },
    imageEditButton: {
      position: 'absolute',
      bottom: '0.9375rem',
      right: '0.9375rem',
      background: 'white',
      border: '1px solid',
      borderRadius: '50%',
      width: '1.875rem',
      height: '1.875rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    },
    showMoreButton: {
      display: 'inline-block',
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
      outline: 'none',
    },
    verifyButton: {
      display: 'inline-block',
      textAlign: 'start',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      padding: '0',
      margin: '0',
    }
  };

  return styles;
}



